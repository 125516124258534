/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

div {
  user-select: auto;
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none;
  -webkit-appearance: none;
}

#cookie-handler-gdpr svg {
  margin: 0 auto;
}

.prose h1 {
  word-break: break-all;
  word-break: break-word;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.react-responsive-modal-modal {
  padding: 0 !important;
  max-width: 90% !important;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaflet-container {
  height: 100%;
  width: 100%;
  z-index: 1;
}
.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}
.leaflet-container a {
  color: #d62e2e;
}

@layer components {
  .markdown-component-wrapper {
    @apply !leading-[19px];
  }

  .markdown-component-wrapper h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply my-5 !font-semibold;
  }

  .markdown-component-wrapper p {
    @apply my-5 !font-light leading-[19px];
  }

  .markdown-component-wrapper a {
    @apply text-primary-500 cursor-pointer break-words;
  }

  .markdown-component-wrapper .data-privacy-list {
    @apply !font-light;
  }

  .estate-markdown-wrapper {
    @apply my-6 md:my-[34px] lg:my-10;
  }

  .estate-markdown-wrapper p {
    @apply flex break-words !text-left !leading-[1.7rem];
  }
}

.custom-pagination {
  display: flex;
  justify-content: space-between;
  max-width: 80%;
  margin: 0 auto;
}

.custom-checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid #c3cbcd;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.custom-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox-container .checkmark:after {
  left: 4.5px;
  top: 0px;
  width: 6px;
  height: 14px;
  border: solid #c3cbcd;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.iiz__img {
  object-fit: cover;
  background-color: white;
  height: 56vw !important;
  width: 100% !important;
}

.iiz__btn {
  border-radius: 50%;
  background-color: #63bd4e !important;
}

.iiz__hint:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.9 19.9'%3E%3Cpath d='M13.9 7.4C13.9 3.8 11 .9 7.4.9S.9 3.8.9 7.4s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5zm5.3 12.5l-6.7-7.2c-1.4 1.3-3.2 2.1-5.1 2.1-4.1 0-7.4-3.3-7.4-7.4S3.3 0 7.4 0s7.4 3.3 7.4 7.4c0 1.7-.6 3.4-1.7 4.7l6.8 7.2-.7.6z' fill='white'/%3E%3C/svg%3E") !important;
}

.iiz__close::before {
  background-image: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff) !important;
}

.Toastify__toast--error {
  background-color: #fecaca !important;
  color: #ef4444 !important;
  --toastify-icon-color-error: #ef4444 !important;
}

.Toastify__toast--success {
  background-color: #d5edcf !important;
  color: #64bd4e !important;
  --toastify-icon-color-error: #64bd4e !important;
}

.floating-button {
  box-shadow: 0px 6px 10px rgba(100, 189, 78, 0.14), 0px 1px 18px rgba(100, 189, 78, 0.12),
    0px 3px 5px rgba(100, 189, 78, 0.2);
}

.hide-tooltip {
  visibility: hidden;
  opacity: 0;
}

.show-tooltip {
  visibility: visible;
  transition: visibility 0.6s linear, opacity 0.6s linear;
  opacity: 1;
}
